<div class="inner-footer wow fadeInUp" data-wow-duration="1s" *ngIf="showDirectAreas">
  <div class="container">

      <div class="footer">
          <div class="sep" *ngIf="showDirectAreas">
              <ul>
                  <li *ngFor="let item of products">
                      <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
                  </li>
              </ul>

              <ul>
                  <li *ngFor="let item of receipts">
                      <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
                  </li>
              </ul>

          </div>

          <div class="sep">
              <ul>
                  <li *ngFor="let item of contacts">
                      <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
                  </li>
              </ul>

              <ul>
                  <li *ngFor="let item of services">
                      <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
                  </li>
              </ul>

          </div>

          <div class="sep">
              <ul>
                  <li *ngFor="let item of onlygal">
                      <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
                  </li>
              </ul>

              <ul>
                  <li *ngFor="let item of customer">
                      <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
                  </li>
              </ul>

          </div>

          <div class="sep">
              <ul>
                  <li>Síguenos en:
                      <ul class="redes">
                          <li *ngFor="let item of social" class="{{item.classes}}">
                              <a [routerLink]="resolveRouterLink(item.url)" aria-label="redes sociales"></a>
                          </li>
                      </ul>
                  </li>
              </ul>
          </div>

      </div>

  </div>
</div>

<div *ngIf="showDirectAreas" class="inner-sub wow fadeInUp" data-wow-duration="1.5s">
  <div class="sub container">

      <div class="left">
          © onLygal {{getYear()}} - v{{version}}
      </div>

      

      <div class="right">
          <ul>
              <li *ngFor="let item of sub">
                  <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
              </li>
          </ul>
      </div>
  </div>
</div>

<div *ngIf="!showDirectAreas" class="inner-sub wow fadeInUp" data-wow-duration="1.5s"  style="margin-top: 15vh">
  <div class="sub container">

      <div class="left">
          © onLygal {{getYear()}} - v{{version}}
      </div>

      

      <div class="right">
          <ul>
              <li *ngFor="let item of sub">
                  <a [routerLink]="resolveRouterLink(item.url)">{{item.title}}</a>
              </li>
          </ul>
      </div>
  </div>
</div>

